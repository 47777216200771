<template>
  <div class="page-box">
    <header-bar
      :swiperActiveIndex="4"
      white
    />
    <div class="banner">
      <div>
        <div class="en">ZHANG<span>WAN</span></div>
        <div class="block">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="title2">掌玩人才培养</div>
        <div class="content2">人才是企业发展的第一资源</div>
      </div>
    </div>
    <div class="join-tab">
      <span
        class="join-tab-item"
        :class="{active:joinTab===1}"
        @click="joinTab=1"
      >培养体系</span>
      <!-- <span
        class="join-tab-item"
        :class="{active:joinTab===2}"
        @click="joinTab=2"
      >优秀员工</span> -->
    </div>

    <transition
      name="fade"
      mode="in-out"
    >
      <div>
        <div
          v-show="joinTab===1"
          class="join-container"
        >
          <div class="page-content">
            <div class="rencai-box">
              <div class="item">
                <img src="/rc/new/rc1.png">
                <div class="content">
                  <div class="head">
                    <p class="title">企业培训</p>
                    <!-- <span class="date">2020-08-15</span> -->
                  </div>
                  <p class="text">
                    公司重视人才的可持续发展，定期开展北极星计划、行星计划、卫星计划、公开课等企业培训项目，不断提高在职员工的职业素质，致力于培养一专多能的“T”型人才。
                  </p>
                </div>
              </div>

              <div class="item">
                <img src="/rc/gpsjh.jpg">
                <div class="content">
                  <div class="head">
                    <p class="title">管培生计划</p>
                    <!-- <span class="date">2020-08-15</span> -->
                  </div>
                  <p class="text">
                    管培生计划是让管培生经过内部重要业务部门的轮岗学习，为公司培养管理岗位人才的一项重要且长期的计划。
                  </p>
                </div>
              </div>

              <div class="item">
                <img src="/rc/new/rc3.png">
                <div class="content">
                  <div class="head">
                    <p class="title">掌成计划</p>
                    <!-- <span class="date">2020-08-15</span> -->
                  </div>
                  <p class="text">
                    掌成计划是公司专门为内部重点员工开拓的除管培生外另一条储干培养通道，由内部选拔的优秀内训师和聘请的外训讲师进行授课，打造掌玩型管理人才。
                  </p>
                </div>
              </div>
              <div class="item">
                <img src="/rc/wcpx.jpg">
                <div class="content">
                  <div class="head">
                    <p class="title">外出培训</p>
                    <!-- <span class="date">2020-08-15</span> -->
                  </div>
                  <p class="text">
                    公司将不定期组织外出学习，从而提高员工的眼界、管理能力和理论知识，并能在实际工作中发挥明显作用。
                  </p>
                </div>
              </div>
              <div class="item">
                <img src="/rc/djtx.jpg">
                <div class="content">
                  <div class="head">
                    <p class="title">带教体系</p>
                    <!-- <span class="date">2020-08-15</span> -->
                  </div>
                  <p class="text">
                    公司秉承“人为本”的价值观，搭建了完善的带教体系，提供一对一带教，使员工能够快速融入团队，适应工作环境。
                  </p>
                </div>
              </div>
              <!-- <div class="item">
          <img src="/rc/rc7.jpg">
          <div class="content">
            <div class="head">
              <p class="title">潜龙计划</p>
            </div>
            <p class="text">
              公司将不定期选拔认同掌玩价值观，有潜力、并在核心岗位有突出贡献的年轻管理者，给予早期经营者的回报，激励他们以经营者心态和投入度，和公司共绘未来美好蓝图
            </p>
          </div>
        </div> -->

            </div>
          </div>
        </div>
        <div
          v-show="joinTab===2"
          class="join-container"
        >
          <div>
            <div
              v-for="(box,bindex) in youxiuList"
              :key="bindex"
              class="youxiu-box"
              :class="['youxiu-box'+bindex]"
            >
              <div
                v-for="(item,index) in box"
                :key="index"
                class="youxiu-item"
                :class="['youxiu-item-'+bindex+'-'+index]"
                :style="{
                  backgroundImage:item
                }"
              >
                <img src="~@/assets/youxiu/陈浩.jpg">
              </div>

            </div>
          </div>
        </div>
      </div>
    </transition>

    <footer-bar />
  </div>
</template>
<script>
export default {
  data() {
    return {
      joinTab: 1,
      youxiuList: [
        ['url(' + require('@/assets/youxiu/陈浩.jpg') + ')', 'url(' + require('@/assets/youxiu/陈宏铭.jpg') + ')', 'url(' + require('@/assets/youxiu/陈静.jpg') + ')', 'url(' + require('@/assets/youxiu/陈睿.jpg') + ')', 'url(' + require('@/assets/youxiu/陈少妹.jpg') + ')', 'url(' + require('@/assets/youxiu/陈小男.jpg') + ')', 'url(' + require('@/assets/youxiu/黄军平.jpg') + ')', 'url(' + require('@/assets/youxiu/黄强伟.jpg') + ')', 'url(' + require('@/assets/youxiu/黄小光.jpg') + ')'],
        ['url(' + require('@/assets/youxiu/罗健.jpg') + ')', 'url(' + require('@/assets/youxiu/牟绍波.jpg') + ')', 'url(' + require('@/assets/youxiu/潘华君.jpg') + ')', 'url(' + require('@/assets/youxiu/史然.jpg') + ')', 'url(' + require('@/assets/youxiu/寿婷婷.jpg') + ')', 'url(' + require('@/assets/youxiu/田宇慧.jpg') + ')', 'url(' + require('@/assets/youxiu/王冰雪.jpg') + ')', 'url(' + require('@/assets/youxiu/王浩杰.jpg') + ')', 'url(' + require('@/assets/youxiu/王一平川.jpg') + ')'],
        ['url(' + require('@/assets/youxiu/韦书怡.jpg') + ')', 'url(' + require('@/assets/youxiu/吴秀沛.jpg') + ')', 'url(' + require('@/assets/youxiu/叶兰洲.jpg') + ')', 'url(' + require('@/assets/youxiu/张静.jpg') + ')', 'url(' + require('@/assets/youxiu/张荣川.jpg') + ')', 'url(' + require('@/assets/youxiu/张亚琨.jpg') + ')', 'url(' + require('@/assets/youxiu/郑灵玲.jpg') + ')', 'url(' + require('@/assets/youxiu/周春香.jpg') + ')', 'url(' + require('@/assets/youxiu/朱春春.jpg') + ')'],
        ['url(' + require('@/assets/youxiu/陈佳.jpg') + ')', 'url(' + require('@/assets/youxiu/程永罗.jpg') + ')', 'url(' + require('@/assets/youxiu/黄程泽.jpg') + ')', 'url(' + require('@/assets/youxiu/刘欢.jpg') + ')', 'url(' + require('@/assets/youxiu/邱佳慧.jpg') + ')', 'url(' + require('@/assets/youxiu/宋格格.jpg') + ')', 'url(' + require('@/assets/youxiu/童凌萍.jpg') + ')', 'url(' + require('@/assets/youxiu/吴朋辉.jpg') + ')', 'url(' + require('@/assets/youxiu/周青青.jpg') + ')']
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
  height: 100%;
  background: url(/bg/7.jpg);
  background-position: center center;
}
.page-content {
  display: flex;
  justify-content: center;
}
.youxiu-box {
  width: 80%;
  margin: 0 auto;
  display: flex;
  &:last-child {
    margin-bottom: 50px;
  }
  .youxiu-item {
    // min-width: 11.1%;
    min-width: 8.8%;
    height: 0;
    padding-bottom: 8.8%;
    // padding: 5px;
    box-sizing: border-box;
    // background: rgba($color: #000000, $alpha: 0.1);
    margin: 10px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 110%;
    background-position-x: center;
    background-position-y: 0;
    // background-position-y: top;
    img {
      display: block;
      width: 100%;
      border-radius: 5px;
      box-shadow: 5px 5px 10px #999;
    }
  }
}
.youxiu-box0,
.youxiu-box1,
.youxiu-box2 {
  .youxiu-item {
    background-size: 130%;
  }
}

.youxiu-box {
  .youxiu-item-0-0 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-0-1 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-0-2 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-0-3 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-0-4 {
    background-position-x: 26%;
    background-position-y: 0;
  }
  .youxiu-item-0-5 {
    background-position-x: 70%;
    background-position-y: 0;
  }
  .youxiu-item-0-6 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-0-7 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-0-8 {
    // background-position-x: 0;
    background-position-y: 6%;
  }

  .youxiu-item-1-0 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-1-1 {
    // background-position-x: 0;
    background-position-y: 2%;
  }
  .youxiu-item-1-2 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-1-3 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-1-4 {
    // background-position-x: 0;
    background-position-y: 5%;
  }
  .youxiu-item-1-5 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-1-6 {
    // background-position-x: 0;
    background-position-y: 2%;
  }
  .youxiu-item-1-7 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-1-8 {
    // background-position-x: 0;
    background-position-y: -3px;
  }

  .youxiu-item-2-0 {
    // background-position-x: 0;
    background-position-y: 2%;
  }
  .youxiu-item-2-1 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-2-2 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-2-3 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-2-4 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-2-5 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-2-6 {
    // background-position-x: 0;
    background-position-y: 5%;
  }
  .youxiu-item-2-7 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-2-8 {
    // background-position-x: 0;
    background-position-y: 0;
  }

  .youxiu-item-3-0 {
    // background-position-x: 0;
    background-position-y: 6%;
  }
  .youxiu-item-3-1 {
    // background-position-x: 0;
    background-position-y: 6%;
  }
  .youxiu-item-3-2 {
    // background-position-x: 0;
    background-position-y: 0;
  }
  .youxiu-item-3-3 {
    // background-position-x: 0;
    background-position-y: 9%;
  }
  .youxiu-item-3-4 {
    // background-position-x: 0;
    background-position-y: 9%;
  }
  .youxiu-item-3-5 {
    // background-position-x: 0;
    background-position-y: 9%;
  }
  .youxiu-item-3-6 {
    // background-position-x: 0;
    background-position-y: 9%;
  }
  .youxiu-item-3-7 {
    // background-position-x: 0;
    background-position-y: 10%;
  }
  .youxiu-item-3-8 {
    // background-position-x: 0;
    background-position-y: 6%;
  }
}
.rencai-box {
  width: 80%;
  max-width: 1160px;
  margin-top: 50px;

  .box-title {
    font-size: 26px;
    margin-bottom: 30px;
    text-align: center;
    color: #ed2908;
  }
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    img {
      width: 355px;
      max-width: 50%;
      max-height: 259px;
    }
    .content {
      max-width: 756px;
      padding: 0 5%;
      .head {
        margin-top: 26px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 34px;
        }
        .date {
          font-size: 14px;
        }
      }
      .text {
        margin-top: 10%;
        line-height: 40px;
      }
    }
  }
}
.join-tab {
  width: 100%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  .join-tab-item {
    padding: 0 10px;
    align-items: center;
    // color: #fdfdfd;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    margin: 0 10px;
    &::after {
      content: ' ';
      background: #ed2908;
      height: 2px;
      width: 0.1%;
      position: absolute;
      left: 50%;
      bottom: -10px;
      transition: all 0.2s ease-in-out;
      opacity: 0.1;
    }
    &:hover::after {
      left: 0%;
      width: 100%;
      opacity: 0.9;
    }
    &.active {
      color: #ed2908;
      &::after {
        bottom: -11px;
        height: 3px;
        left: 0%;
        opacity: 0.9;
        width: 100%;
        background: #ed2908;
      }
    }
  }
}
</style>